import { Avatar, Nav, Navbar } from "rsuite";
import HomeIcon from "@rsuite/icons/legacy/Home";
import { NavLink } from "react-router-dom";
import { auth } from "../../config/firebase";
import { useContext } from "react";
import { signOut } from "firebase/auth";
import { AuthContext } from "../contextProvider/AuthProvider";

const MainNavigation = ({ onSelect, activeKey, ...props }) => {
    const {currentUser} = useContext(AuthContext);
    const handleLogout = async () => {
        await signOut(auth);
    }

    return (
        <Navbar {...props}>
            <Nav appearance="subtle" onSelect={onSelect} activeKey={activeKey}>
                <Nav.Item icon={<HomeIcon />} as='div'>
                    <NavLink to="/home">Home</NavLink>
                </Nav.Item>
                <Nav.Item>
                    <NavLink to="/fiidii" as='div'>FII/DII Data</NavLink>
                </Nav.Item>
                <Nav.Item>News</Nav.Item>
            </Nav>
            <Nav pullRight>
                <Nav.Menu title={currentUser?.displayName}
                    icon={<Avatar src={currentUser?.photoURL} alt={currentUser?.displayName} />} >
                    <Nav.Item eventKey="profile">Profile</Nav.Item>
                    <Nav.Item eventKey="setting">Setting</Nav.Item>
                    <Nav.Item eventKey="logout" onClick={handleLogout}>Logout</Nav.Item>
                </Nav.Menu>
            </Nav>
        </Navbar>
    );
}

export default MainNavigation;