import { createSlice } from "@reduxjs/toolkit";
import { fetchDailyData } from "../service/FIIDII/DailyDataService";
import { sortDates } from "../utility/date";

const initialState = {
    dailydata: {
        sortedDates: [],
        indexClosingPrices: {
            NIFTY50: {},
            FINNIFTY: {},
            NIFTYBANK: {},
            MIDCPNIFTY: {},
        },
        indexFutureOI: {
            fii: {},
            dii: {},
            pro: {},
            retail: {}
        }
    }
};

export const dailyDataSlice = createSlice({
    name: 'dailydata',
    initialState,
    reducers: {
        updateDailyData(state, action) {
            const newState = JSON.parse(JSON.stringify(initialState));
            newState.dailydata.sortedDates = sortDates(Object.keys(action.payload.apiData));
            Object.entries(action.payload.apiData).forEach(([key, value]) => {
                newState.dailydata.indexClosingPrices.NIFTY50[key] = value.indexClosingPrice.NIFTY50;
                newState.dailydata.indexClosingPrices.FINNIFTY[key] = value.indexClosingPrice.FINNIFTY;
                newState.dailydata.indexClosingPrices.MIDCPNIFTY[key] = value.indexClosingPrice.MIDCPNIFTY;
                newState.dailydata.indexClosingPrices.NIFTYBANK[key] = value.indexClosingPrice.NIFTYBANK;

                newState.dailydata.indexFutureOI.fii[key] = value.netFutureOI.fii;
                newState.dailydata.indexFutureOI.dii[key] = value.netFutureOI.dii;
                newState.dailydata.indexFutureOI.pro[key] = value.netFutureOI.pro;
                newState.dailydata.indexFutureOI.retail[key] = value.netFutureOI.retail;
            });
            state.dailydata = newState.dailydata;
        }
    }
})

export const dailyDataActionCreator = (startDate, endDate) => {
    return async (dispatch) => {
        const apiData = await fetchDailyData(startDate, endDate);
        dispatch(dailyDataSlice.actions.updateDailyData({
            apiData
        }))
    }
}