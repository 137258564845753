import { formattedDateddMMMyyyy } from '../../utility/date';

const dailyDataBaseUrl = "https://fetchdailydatafordaterange-ilphvkxewa-uc.a.run.app/";

export const fetchDailyData = async (startDate, endDate) => {
    return await fetch(dailyDataBaseUrl + formattedDateddMMMyyyy(startDate) + '/' + formattedDateddMMMyyyy(endDate))
    .then(response => {
        if (!response.ok) {
            throw new Error('Error while fetching daily data');
        }
        return response.json();
    }).catch(error => {
        throw new Error('Failed to fetch daily data' + error);
    });
}