import React, { useContext } from 'react';
import { signInWithPopup, signOut } from 'firebase/auth';
import { auth, googleAuthProvider } from '../../config/firebase';
import { Navigate, redirect, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contextProvider/AuthProvider';
import { Button, Container, Content, Divider, FlexboxGrid, IconButton, Panel } from 'rsuite';
import GoogleButton from 'react-google-button';

export default function SignUp() {

    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const signinWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleAuthProvider);
            navigate('/home');
        } catch (err) {
            redirect('/');
        }
    }

    const handleLogin = (provider) => {
        if (provider === 'google') {
            signinWithGoogle();
        }
    };

    return (
        <>
            {
                !currentUser ?
                    // <button onClick={signinWithGoogle}>SignUp</button>
                    <Container>
                        <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <FlexboxGrid justify="center">
                                <FlexboxGrid.Item colspan={30}>
                                    <Panel header={<h3>Login</h3>} bordered style={{ padding: 20 }}>
                                        <GoogleButton label='Sign in with Google' onClick={signinWithGoogle} />
                                        <Divider>Or</Divider>
                                        <Button block color="cyan" onClick={() => handleLogin('twitter')}>
                                            <IconButton icon="twitter" /> Login with Twitter
                                        </Button>
                                        <Button block color="blue" onClick={() => handleLogin('facebook')}>
                                            <IconButton icon="facebook" /> Login with Facebook
                                        </Button>
                                    </Panel>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Content>
                    </Container>
                    :
                    <Navigate to='/home' />
            }
        </>
    )
}