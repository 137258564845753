export const formattedDateddMMMyyyy = (date) => { 
    return date.toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
};

// Sort dates array which is in format dd-MM-yyyy
export const sortDates = (dates) => {
    dates.sort(function(a,b) {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
    });
    return dates;
}

// Sort dates array which is in format dd-MMM-yyyy
// export const sortedDates = dateStrings.sort((a, b) => {
//     const dateA = new Date(a);
//     const dateB = new Date(b);
//     return dateA - dateB;
// });