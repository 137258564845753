import { Provider } from 'react-redux';
import './App.css';
import FIIDIIData from './FIIDII/FIIDIIData';
import store from '../store/index';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './home/HomePage';
import RootLayout from './home/RootLayout';
import ErrorPage from './home/ErrorPage';
import SignUp from './auth/SignUp';
import LandingPage from './LandingPage';
import { auth } from '../config/firebase';
import AuthProvider from './contextProvider/AuthProvider';

const router = createBrowserRouter([
  { path: '/', element: <LandingPage /> },
  {
    path: '/',
    element: <RootLayout />,
    id: "root",
    children: [
      { path: '/home', element: <HomePage /> },
      { path: '/fiidii', element: <FIIDIIData /> }
    ],
    errorElement: <ErrorPage />,
    loader: () => auth.currentUser
  },
  {
    path: '/auth',
    element: <SignUp />
  }
]);

function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </AuthProvider>
  );
}

export default App;
