import { useContext, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import './LandingPage.css';
import { auth } from "../config/firebase";
import { AuthContext } from "./contextProvider/AuthProvider";

const LandingPage = () => {
    const { currentUser } = useContext(AuthContext);

    if (currentUser !== null) {
        return <Navigate to={'/home'} />
    }

    return (
        <>
            <header>
                <div className="user-actions">
                    <Link to="/auth">Login/Sign Up</Link>
                </div>
            </header>

            <section id="hero">
                <h1>Financial Insights</h1>
                <p>Your source for FII/DII data and market news.</p>
            </section>

            <section id="fiidi-data">
                <h2>FII/DII Data</h2>
            </section>

            <section id="market-news">
                <h2>Market News</h2>
            </section>

            <footer>
                <p>&copy; 2023 Financial Insights. All rights reserved.</p>
            </footer>
        </>
    );
}

export default LandingPage;