import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { useDispatch, useSelector } from 'react-redux';
import { dailyDataActionCreator } from '../../store/dailydata';
import { Container, Content, DateRangePicker, Dropdown, Header, Sidebar, Stack } from 'rsuite';
import './FIIDIIData.css';

const FIIDIIData = () => {
  const data = [
    [
      { type: "date", label: "Date" },
      "FII net future OI position",
      "Nifty 50",
    ]
  ];

  const dispatch = useDispatch();
  const dailyMarketData = useSelector(state => state.dailydata);
  const [graphData, setGraphData] = useState(data);
  const [dateRange, setDateRange] = useState([new Date('01-Nov-2023'), new Date()]);
  const [selectedClient, setSelectedClient] = useState('fii');
  const [selectedIndex, setSelectedIndex] = useState('NIFTY50');

  const options = {
    chart: {
      title: `FII Net future OI position VS ${selectedIndex}`,
    },
    width: 1240,
    height: 680,
    series: {
      // Gives each series an axis name that matches the Y-axis below.
      0: { axis: "client_type" },
      1: { axis: "index_name" },
    },
    axes: {
      // Adds labels to each axis; they don't have to match the axis names.
      y: {
        client_type: { label: "FII Net future OI" },
        index_name: { label: "NIFTY 50" },
      },
    },
  };

  useEffect(() => {
    const dailydata = dailyMarketData.dailydata;
    if (dailydata.sortedDates.length === 0) {
      return;
    }
    const updatedGraphData = [];
    updatedGraphData.push(data[0]);
    dailydata.sortedDates.forEach(dateString => {
      updatedGraphData.push([new Date(dateString),
      dailydata.indexFutureOI[selectedClient][dateString],
      dailydata.indexClosingPrices[selectedIndex][dateString]]);
    });
    setGraphData(updatedGraphData);
  }, [dailyMarketData, selectedClient, selectedIndex]);

  useEffect(() => {
    dispatch(dailyDataActionCreator(dateRange[0], dateRange[1]));
  }, [dispatch, dateRange]);

  const handleDateUpdate = (event) => {
    if (event === null) {
      const pastDate = new Date();
      pastDate.setDate(pastDate.getDate() - 100);
      setDateRange([
        pastDate,
        new Date()
      ])
    } else {
      setDateRange([
        new Date(event[0]),
        new Date(event[1])
      ])
    }
  }

  const handleSelectIndex = (eventkey) => {
    setSelectedIndex(eventkey);
  }

  const handleSelectClient = (eventKey) => {
    setSelectedClient(eventKey);
  }

  return (
    <Container>
      <Sidebar className='sidebar'>
        <Stack direction='column' spacing={4}>
          <label className='sidebar__label'>
            Date range
            <DateRangePicker value={dateRange} onChange={handleDateUpdate} />
          </label>
          <label className='sidebar__label'>
            Index
            <Dropdown title={selectedIndex} activeKey={selectedIndex} onSelect={handleSelectIndex} style={{width:'100%'}}>
              {
                Object.keys(dailyMarketData.dailydata.indexClosingPrices).map(
                  index => <Dropdown.Item key={index} eventKey={index}>{index}</Dropdown.Item>
                )
              }
            </Dropdown>
          </label>
          <label className='sidebar__label'>
            Client Type
            <Dropdown title={selectedClient} activeKey={selectedClient} onSelect={handleSelectClient} style={{width:'100%'}}>
              {
                Object.keys(dailyMarketData.dailydata.indexFutureOI).map(
                  client => <Dropdown.Item key={client} eventKey={client}>{client}</Dropdown.Item>
                )
              }
            </Dropdown>
          </label>
        </Stack>
      </Sidebar>
      <Container>
        <Header>

        </Header>
        <Content>
          <Chart
            chartType="Line"
            width="100%"
            height="400px"
            data={graphData}
            options={options}
          />
        </Content>
      </Container>
    </Container>
  );
}

export default FIIDIIData;