import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet, useLoaderData, useNavigate } from "react-router-dom"
import MainNavigation from "./MainNavigation";
import { AuthContext } from '../contextProvider/AuthProvider';

const RootLayout = () => {
    const [activeKey, setActiveKey] = React.useState(null);
    const { currentUser } = useContext(AuthContext);

    return (
        <>
            {
                currentUser && <>
                    <MainNavigation onSelect={setActiveKey} activeKey={activeKey} />
                    <Outlet />
                </>
            }
            {
                currentUser === null && <Navigate to={"/auth"} />
            }
        </>
    )
}

export default RootLayout;